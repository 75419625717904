import React, { FC } from 'react';
import { QuickProps } from '@/newcar/ui/quick';

import * as styles from './Plan.module.styl';

type Props = {
  thumbnail: string,
  quickData: QuickProps,
  price11: number,
}

const Plan: FC<Props> = ({
  thumbnail, quickData, price11,
}) => (
  <div className={`${styles.container}`}>
    <div className={`${styles.space} is-flex-desktop`}>
      <div className={styles.content}>
        <img src={thumbnail} width="360" height="202" alt="車両画像" decoding="async" />
      </div>
      <div className={`${styles.content} ${styles.txt}`}>
        <h3 className="is-hidden-desktop is-size-４ has-text-grey-dark has-text-centered has-text-weight-semibold has-background-white">
          {quickData.name.toString()}
          <span className="has-text-weight-light">（11年賃貸）</span>
        </h3>
        <div className={`columns is-mobile is-centered ${styles.hasBorderBottom}`}>
          <div className="column is-flex has_vertical_centered is-size-5 is-narrow has-text-grey-dark has-text-weight-semibold">
            月額
            <br />
            定額
          </div>
          <div className="column is-narrow">
            <div className="is-size-5 has-text-grey-dark has-text-weight-semibold is-flex">
              <span className={`is-size-1 has-text-weight-bold is-block ${styles.isOrange}`}>{price11.toLocaleString()}</span>
              <span className={`${styles.yen} is-size-5 is-padding-bottom-2`}>円〜</span>
            </div>
          </div>
        </div>
        <p className="is-margin-top-4 is-size-5 has-text-centered has-text-grey-dark">
          <span className={`has-text-weight-semibold ${styles.isOrange}`}>頭金0円、ボーナス払い0円！</span>
          <br />
          環境性能割、登録諸費用、自動車税（種別割）
          <br />
          重量税、自賠責保険料、
          <span className="has-text-weight-bold">もろもろコミコミ！</span>
        </p>
      </div>
    </div>
  </div>
);

export default Plan;
