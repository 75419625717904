import React, { FC } from 'react';
import CARMO_CONST from '@/common/util/const';

// img
import quickPcImg from '@/newcar/ui/quick/img/image-quick-pc.svg';
import quickSpImg from '@/newcar/ui/quick/img/image-quick-sp.svg';

import * as styles from './Merit.module.styl';

type Props = {
  bodyType: CARMO_CONST.BODY_TYPE.ID,
}

const Merit: FC<Props> = ({
  bodyType,
}) => (
  <div className={`${styles.container} has-background-white`}>
    <h2 className="title has-text-centered">クイック審査のメリット</h2>
    <p className="is-size-5 has-text-centered-tablet has-text-grey-dark">
      { CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyType) === 'compact'
        ? (
          <>
            コンパクトカーをご希望で、お急ぎの方には特におススメ！
            <br />
            お気軽に審査申込ができます。
          </>
        ) : ('')}
      { CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyType) === 'suv'
        ? (
          <>
            SUVをご希望で、お急ぎの方には特におススメ！
            <br />
            人気のSUVのプランを選択するだけでお気軽に審査申込ができます。
          </>
        ) : ('')}
      { CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyType) !== 'compact' && CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyType) !== 'suv'
        ? (
          <>
            軽自動車をご希望で、お急ぎの方には特におススメ！
            <br />
            人気の軽自動車2つのプランから、どちらかを選択するだけでお気軽に審査申込ができます。
          </>
        ) : ('')}
    </p>
    <div className="img has-text-centered is-margin-bottom-7 is-margin-top-7">
      <img src={quickPcImg} className="is-hidden-touch is-full-width" alt="クイック審査・通常の申し込み" decoding="async" />
      <img src={quickSpImg} className={`is-hidden-desktop is-full-width ${styles.spImg}`} alt="クイック審査・通常の申し込み" decoding="async" />
    </div>
    <div className="is-margin-bottom-7">
      <p className="is-size-6 has-text-grey-dark">※クイック審査をご利用いただいた場合でも、大幅な車種変更などされると再審査が必要となります。</p>
      <p className="is-size-6 has-text-grey-dark">※審査結果として、連帯保証人や保証会社が必要な場合がございます。また審査に通らない場合もございますので予めご了承ください。</p>
    </div>
  </div>
);

export default Merit;
