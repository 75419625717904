import React, { FC } from 'react';
import SEOBase from '@/newcar/ui/common/organism/SEO';

type QuickSEOProps = {
  quickName: string;
  bodyType: string;
};

const SEO: FC<QuickSEOProps> = ({ quickName, bodyType }) => {
  // title
  const title = `クイック審査（${quickName}） おトクにマイカー 定額カルモくん`;

  // description
  const description = `（${quickName}）${bodyType}をご希望で、お急ぎの方に特にオススメなカーリースのクイック審査のページです。定額カルモくんのカーリースは、最大49,500円のネット割でおトクに新車にお乗りいただけます。新車、中古車問わず車選びはオンラインならではの個人向け格安マイカーリース「定額カルモくん」にお任せください。`;

  return (
    <SEOBase
      title={title}
      description={description}
      ogTitle={title}
      ogDescription={description}
      ogUrl={`${process.env.GATSBY_SITE_URL}`}
      twitterTitle={title}
      twitterDescription={description}
    />
  );
};

export default SEO;
