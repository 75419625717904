import React, { FC } from 'react';

import CARMO_CONST from '@/common/util/const';

// img
import mc004Img from '@/newcar/ui/quick/img/mc004.png';
import mc007Img from '@/newcar/ui/quick/img/mc007.png';
import mc005CompactImg from '@/newcar/ui/quick/img/mc005_compact.png';
import mc005SUVImg from '@/newcar/ui/quick/img/mc005_SUV.png';
import mc005Img from '@/newcar/ui/quick/img/mc005.png';
import mc008CompactImg from '@/newcar/ui/quick/img/mc008_compact.png';
import mc008SUVImg from '@/newcar/ui/quick/img/mc008_SUV.png';
import mc008Img from '@/newcar/ui/quick/img/mc008.png';
import mc006Img from '@/newcar/ui/quick/img/mc006.png';
import mc009Img from '@/newcar/ui/quick/img/mc009.png';

import * as styles from './Recommended.module.styl';

type Props = {
  bodyType: CARMO_CONST.BODY_TYPE.ID,
}

const Recommended: FC<Props> = ({ bodyType }) => (
  <div className={`${styles.container} has-background-white`}>
    <h2 className="title has-text-centered">こんな方にオススメ</h2>
    <div className={`${styles.space} is-flex-desktop`}>
      <div className={`${styles.discription} has-text-centered-touch`}>
        <img src={mc004Img} className="is-hidden-touch" alt="できるだけ早く車が欲しい方" width="220" height="210" decoding="async" />
        <img src={mc007Img} className="is-hidden-desktop is-margin-bottom-3" alt="できるだけ早く車が欲しい方" width="274" height="180" decoding="async" />
        <p className="is-size-5 has-text-grey is-margin-top-4">審査とオプション等の検討が同時進行で進められます。納車までの時間短縮が可能です。</p>
      </div>
      <div className={styles.discription}>
        { CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyType) === 'compact'
          ? (
            <div className="has-text-centered-touch">
              <img src={mc005CompactImg} className="is-hidden-touch" alt="人気のコンパクトカーに乗りたい方" width="220" height="210" decoding="async" />
              <img src={mc008CompactImg} className="is-hidden-desktop is-margin-bottom-3" alt="人気の軽自動車に乗りたい方" width="274" height="180" decoding="async" />
              <p className="is-size-5 has-text-grey is-margin-top-4">人気の車に乗りたいけど、コスパも重視したい方におすすめです。</p>
            </div>
          ) : ('')}
        { CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyType) === 'suv'
          ? (
            <div className="has-text-centered-touch">
              <img src={mc005SUVImg} className="is-hidden-touch" alt="人気のコンパクトカーに乗りたい方" width="220" height="210" decoding="async" />
              <img src={mc008SUVImg} className="is-hidden-desktop is-margin-bottom-3" alt="人気の軽自動車に乗りたい方" width="274" height="180" decoding="async" />
              <p className="is-size-5 has-text-grey is-margin-top-4">人気の車に乗りたいけど、コスパも重視したい方におすすめです。</p>
            </div>
          ) : ('')}
        { CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyType) !== 'compact' && CARMO_CONST.BODY_TYPE.ID_TO_KEY(bodyType) !== 'suv'
          ? (
            <div className="has-text-centered-touch">
              <img src={mc005Img} className="is-hidden-touch" alt="人気のコンパクトカーに乗りたい方" width="220" height="210" decoding="async" />
              <img src={mc008Img} className="is-hidden-desktop is-margin-bottom-3" alt="人気の軽自動車に乗りたい方" width="274" height="180" decoding="async" />
              <p className="is-size-5 has-text-grey is-margin-top-4">人気も実力も兼ね備えた2つのタイプから安心してお選びいただけます。</p>
            </div>
          ) : ('')}
      </div>
      <div className={`${styles.discription} has-text-centered-touch`}>
        <img src={mc006Img} className="is-hidden-touch" alt="どのクルマを選んで良いかわからない方" width="220" height="210" decoding="async" />
        <img src={mc009Img} className="is-hidden-desktop is-margin-bottom-3" alt="どのクルマを選んで良いかわからない方" width="274" height="180" decoding="async" />
        <p className="is-size-5 has-text-grey is-margin-top-4">
          おクルマ選びのお悩み不要！
          <br />
          セールス担当と相談可能です。
        </p>
      </div>
    </div>
  </div>
);

export default Recommended;
