/* eslint-disable max-len */
import { observable, action } from 'mobx';
import { store } from '@/common/core/store/decorator';
import CarDigest from '@/common/core/model/carDigest';
import { VehicleTypes } from '@/common/core/model/catalog/vehicleTypes';
import VehicleGrade from '@/common/core/model/catalog/vehicleGrade';
import { QuickProps } from '@/newcar/ui/quick';
import { EntryBreakdown } from '@/newcar/core/store/dataset/entryBreakdown';
import CARMO_CONST from '@/common/util/const';
import NEWCAR_CONST from '@/newcar/util/const';

interface IQuickApplication {
  name: string;
  data: EntryBreakdown;
  lowestPrice: number;
  bodyTypeId: CARMO_CONST.BODY_TYPE.ID;
}

/**
 * ボディータイプID取得
 * @param carDigest
 * @returns
 */
function getBodyTypeId(carDigest: CarDigest): CARMO_CONST.BODY_TYPE.ID {
  const { bodyType } = carDigest;
  if (bodyType === CARMO_CONST.BODY_TYPE.ID.UNSELECTED) {
    if (carDigest.isMiniCar) {
      return CARMO_CONST.BODY_TYPE.ID.MINI_CAR;
    }
    return CARMO_CONST.BODY_TYPE.ID.OTHER;
  }
  return bodyType;
}

@store()
export default class QuickApplicationManagerStore {
  @observable quickApplication?: IQuickApplication;

  /**
   * Action:現在画面上で表示するクイック審査用車種を設定
   * @param {string} id
   * @returns
   */
  @action
  public setQuickApplication(carDigest: CarDigest, vehicleType: VehicleTypes, quickData: QuickProps): void {
    const application: IQuickApplication = {
      name: quickData.name,
      data: new EntryBreakdown(),
      lowestPrice: 0,
      bodyTypeId: CARMO_CONST.BODY_TYPE.ID.UNSELECTED,
    };

    // digest設定
    application.data.car = carDigest;

    // 最安値(lowestPrice)取得
    application.lowestPrice = carDigest.taxedMinPrice11;

    // ボディータイプID取得
    application.bodyTypeId = getBodyTypeId(carDigest);

    // ボディタイプ取得
    application.data.bodyType = CARMO_CONST.BODY_TYPE.NAME(carDigest.bodyType);

    // グレード取得
    if (!vehicleType?.vehicleGrades) return;
    const { vehicleGrades } = vehicleType;
    let grade: VehicleGrade | undefined;
    // ミライースの場合、「2WD 5ドア X リミテッド SA III 4人 660cc ガソリン DCVT」のグレードを利用する
    if (quickData.carId.toString() === '10017') {
      grade = vehicleGrades.find((vehicleGrade) => vehicleGrade.id?.toString() === '63908');
      if (grade) {
        // review_infoでの表示金額を格納
        application.data.referrerLpMonthlyPrice = carDigest.taxedMinPrice11.toString();
      }
    }
    if (!grade) {
      // 最安値のグレードを取得
      grade = vehicleGrades.filter((vehicleGrade: VehicleGrade) => vehicleGrade.leasingFee132)
        .reduce((a, b) => ((a.leasingFee132 && b.leasingFee132 && a.leasingFee132 > b.leasingFee132) ? b : a));
    }
    if (!grade) return;
    application.data.grade = grade;

    // リース年数取得
    application.data.term = NEWCAR_CONST.TERM.MAX();

    // 備考(memo)取得
    application.data.memo = NEWCAR_CONST.QUICK.MEMO;

    // もらえるオプション料金
    application.data.wantCar = { price: grade.freeTransferDiffLeasingFeeTaxed132 || 0 };

    // MEMO: オプションはバックエンド仕様が確認できてから肉付けする
    this.quickApplication = application;
  }
}

export const quickApplicationManagerStore = new QuickApplicationManagerStore();
