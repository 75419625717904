/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { PageProps, Link } from 'gatsby';
import { useLocation } from '@reach/router';
import QueryString from 'query-string';
import Layout from '@/common/ui/template/Layout';
import Util from '@/common/util/util';
import NEWCAR_CONST from '@/newcar/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import { QuickProps } from '@/newcar/ui/quick';

import Header from '@/newcar/ui/common/organism/Header';
import Breadcrumb from '@/newcar/ui/common/molecule/Breadcrumb';
import SEO from '@/newcar/ui/quick/organism/SEO';
import CarDigest from '@/common/core/model/carDigest';
import { VehicleTypes } from '@/common/core/model/catalog/vehicleTypes';
import Plan from '@/newcar/ui/quick/organism/Plan';
import Recommended from '@/newcar/ui/quick/organism/Recommended';
import Merit from '@/newcar/ui/quick/organism/Merit';
import { setAllList } from '@/newcar/util/setAllList';
import EntryBreakdownManagerStoreContext from '@/newcar/ui/common/context/EntryBreakdownManagerStoreContext';
import QuickApplicationManagerContext from '@/newcar/ui/common/context/QuickApplicationManagerContext';

import Form from '@/newcar/ui/contract/organism/Form';
import { ContractType } from '@/newcar/util/const/contract';

// image
import quickImg from '@/newcar/ui/quick/img/quick.svg';
import arrow from '@/newcar/ui/common/img/object/icon/arrow-down-circle.svg';

import * as styles from './index.module.styl';

// AB-test: 4874281366
const QUERY_TO_QUICK_MESSAGE: { [K in string]: string } = {
  p1: '今スグ人気のタイプで申し込める！',
  p2: 'クルマ選びもサポートします',
  p3: 'スグ申し込める！',
  p4: 'クルマ選びに迷ったら',
  p5: '【最短】明日結果が分かる！',
  p6: 'ネットで完結！',
};
// AB-test: 4874281366
const TEST_TARGET_IDS = ['10013', '10017'];

type Props = {
  carDigest: CarDigest;
  vehicleType: VehicleTypes;
  quickData: QuickProps;
}

const Template: FC<PageProps<{}, Props>> = ({ pageContext }) => {
  const {
    carDigest,
    vehicleType,
    quickData,
  } = pageContext;

  const [quickMessage, setQuickMessage] = useState('');

  const location = useLocation();
  const query = QueryString.parse(location.search);

  // ストア
  const entryBreakdownManagerStore = useContext(EntryBreakdownManagerStoreContext);
  const quickApplicationManagerStore = useContext(QuickApplicationManagerContext);

  const quickName = quickData.name.toString();

  // storeの商品情報を最新化
  setAllList();

  // クイック申込に必要な情報をストアに保存する
  quickApplicationManagerStore.setQuickApplication(carDigest, vehicleType, quickData);

  // 表示料金
  const displayPrice = carDigest.taxedMinPrice11 + (quickApplicationManagerStore.quickApplication?.data.wantCar?.price || 0);

  useEffect(() => {
    // entryBreakdownの更新
    if (quickApplicationManagerStore.quickApplication?.data) {
      entryBreakdownManagerStore.setEntryBreakdown(quickApplicationManagerStore.quickApplication?.data);
    }
    // memoの追記
    let memo = query.from ? `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.LP_QUICK}` : `|プラン：${NEWCAR_CONST.PLAN_PATTERN.NAME.MAIN_QUICK}`;
    memo += entryBreakdownManagerStore.entryBreakdown.memo || '';
    memo += query.from ? `|ユーザー経由ページ：${query.from}` : ''; // ユーザー経由ページがあれば入れる
    memo += `|クイック審査：${quickData.name}|ユーザー表示月額：${displayPrice}`;
    // 流入元分類
    const referrerType = NEWCAR_CONST.APPLICATION_TYPE.REFERRER_TYPE.MAIN;
    // 車種状態
    const carCondition = NEWCAR_CONST.APPLICATION_TYPE.CAR_CONDITION.NEW_CAR;
    // 車種選択状況
    const carSelectStatus = NEWCAR_CONST.APPLICATION_TYPE.CAR_SELECT.TYPE;
    // プラン詳細
    let planDetails = '';
    switch (quickName) {
      case NEWCAR_CONST.APPLICATION_TYPE.PLAN_DETAIL_NAME.AFFORDABLE_KEI:
        planDetails = NEWCAR_CONST.APPLICATION_TYPE.PLAN_DETAIL.AFFORDABLE_KEI;
        break;
      case NEWCAR_CONST.APPLICATION_TYPE.PLAN_DETAIL_NAME.COMFORTABLE_KEI:
        planDetails = NEWCAR_CONST.APPLICATION_TYPE.PLAN_DETAIL.COMFORTABLE_KEI;
        break;
      default:
        planDetails = '';
        break;
    }

    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'memo', payload: memo });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'referrerType', payload: referrerType });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'carCondition', payload: carCondition });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'carSelectStatus', payload: carSelectStatus });
    entryBreakdownManagerStore.updateEntryBreakdown({ field: 'planDetails', payload: planDetails });
    entryBreakdownManagerStore.saveToSessionStorage();
  }, []);

  useEffect(() => {
    if (TEST_TARGET_IDS.includes(quickData.carId.toString()) && query.qt) {
      setQuickMessage(QUERY_TO_QUICK_MESSAGE[query.qt.toString()]);
    }
  }, []);

  return (
    <Layout>
      <SEO quickName={quickName} bodyType={quickData.bodyType} />

      {/* ヘッダー */}
      <Header />
      {/* パンくず/動的 */}
      <Breadcrumb
        items={[
          { to: '/', label: '新車TOP' },
          { label: 'クイック審査' },
        ]}
      />

      <main className={`is-block ${styles.mainContent}`}>
        <div className={`${styles.mv} has-text-centered has-background-white`}>
          <div className="container">
            <h1 className="is-margin-4">
              <p className={`has-text-centered is-margin-bottom-1 ${styles.quickMessage}`}>{quickMessage}</p>
              <img src={quickImg} className={styles.quickImg} alt="クイック審査" width="300" height="60" decoding="async" />
            </h1>
            <p className="has-text-left has-text-centered-desktop is-margin-top-5">
              クイック審査は、定額カルモくんの人気のタイプでお申し込みいだだき、
              <br className="is-hidden-touch" />
              <span className="has-text-weight-semibold">通常申込より早く審査結果をご確認いただける</span>
              サービスです。
            </p>
          </div>

          {/* プランコンテンツ */}
          <Plan
            thumbnail={carDigest.thumbnailUrl}
            quickData={quickData}
            price11={displayPrice}
          />

          <button
            type="button"
            className={`button ${styles.nextBtn}`}
            onClick={() => Util.scrollToId('scroll-point')}
            data-gtm="gtm-newcar-quick-ctaButtonTop"
          >
            <span className={`${styles.pop}`}>最短翌日結果が分かる！</span>
            <img src={quickImg} alt="クイック審査" width="100" height="20" decoding="async" />
            <span className="has-text-white has-text-weight-semibold">お申し込みへ進む</span>
            <img src={arrow} className="is-margin-left-5" alt="矢印" width="20" height="20" decoding="async" />
          </button>
          <p className="is-size-6 is-margin-top-3">※審査後でも車種・グレード・オプションは変更可能です。</p>
          <Link
            to={NEWCAR_DEFINE.PATH.DETAIL(carDigest.id__normalized)}
            className={`is-size-6 is-margin-top-3 has-text-grey ${styles.hasTextUnderline}`}
            data-gtm="gtm-newcar-quick-detailLinkTop"
          >
            自分で料金をシミュレーションする
          </Link>
        </div>

        <section className={`${styles.mc} has-background-white-ter`}>
          <div className="container">
            {/* オススメのコンテンツ */}
            <Recommended bodyType={carDigest.bodyType} />

            {/* メリットコンテンツ */}
            <Merit bodyType={carDigest.bodyType} />

            {/* スクロール位置 */}
            <section className={`${styles.formContainer} ${styles.orangeLine} has-background-white is-padding-bottom-5`} id="scroll-point">
              <div className="has-text-centered is-padding-left-5 is-padding-right-5">
                <p className={`${styles.speech} is-margin-bottom-2`}>最短翌日結果がわかる</p>
                <img src={quickImg} alt="クイック審査" width="200" height="40" decoding="async" />
                <p className="is-padding-top-4 is-padding-bottom-4">
                  <strong>キャンセル・プラン変更・車両の変更は可能</strong>
                  ですので、お気軽にお申し込みください。
                </p>
                <div className={`${styles.priceBox} ${styles.txt} ${styles.fullWidth} is-margin-top-5 is-margin-bottom-5`}>
                  <div className="columns">
                    <h3 className="is-size-4 has-text-grey-dark has-text-weight-semibold has-background-white">
                      {quickName}
                      <span className="has-text-weight-light">（11年賃貸）</span>
                    </h3>
                  </div>
                  <div className={`columns is-mobile is-centered ${styles.boxIn} ${styles.boxInBorder}`}>
                    <div className="column is-block is-size-5 has-text-left is-narrow has-text-grey-dark has-text-weight-semibold">
                      月額
                      <br />
                      定額
                    </div>
                    <div className="column is-narrow">
                      <p className="is-size-5 has-text-grey-dark has-text-weight-semibold is-flex">
                        <span className={`${styles.isOrange} is-size-1 has-text-weight-bold is-block is-margin-left-2`}>
                          {displayPrice.toLocaleString()}
                        </span>
                        <span className={`is-size-5 is-margin-right-2 ${styles.yen}`}>円〜</span>
                        <img src={carDigest.thumbnailUrl} alt="車両画像" width="80" height="45" decoding="async" className={`${styles.quickThumbnail}`} />
                      </p>
                    </div>
                  </div>
                  <p className={`${styles.priceDetail} is-margin-top-4 is-size-5 has-text-grey-dark`}>
                    <span className={`${styles.isOrange} has-text-weight-semibold`}>頭金0円、ボーナス払い0円！</span>
                    <br />
                    環境性能割、登録諸費用、自動車税（種別割）
                    <br />
                    重量税、自賠責保険料、
                    <span className="has-text-weight-bold">もろもろコミコミ！</span>
                  </p>
                </div>
              </div>

              {/* フォーム */}
              <Form type={'contact_info' as ContractType} isLp />
            </section>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Template;
