// extracted by mini-css-extract-plugin
export const mainContent = "index-module--main-content--1TLPb";
export const isSizeImg = "index-module--is-size-img--1d0xU";
export const quickImg = "index-module--quick-img--1jlSN";
export const quickMessage = "index-module--quick-message--3OqPG";
export const mv = "index-module--mv--hEw9c";
export const ttlUnder = "index-module--ttl-under--2xNvI";
export const mc = "index-module--mc--U7ymy";
export const lCtt = "index-module--l-ctt--32nnT";
export const first = "index-module--first--1ECBQ";
export const att = "index-module--att--CYrNb";
export const second = "index-module--second--jHB8A";
export const cttIn = "index-module--ctt-in--mbaFo";
export const boxIn = "index-module--box-in--2hcSe";
export const third = "index-module--third--18RZ5";
export const txt = "index-module--txt--3hjuZ";
export const lBtn = "index-module--l-btn--21JUI";
export const button = "index-module--button--o1lHq";
export const iconBlackTerArrow = "index-module--icon-black-ter-arrow--3J-uN";
export const orangeButton = "index-module--orange-button--SLHm6";
export const buttonSize = "index-module--button-size--2pM8K";
export const whiteButton = "index-module--white-button--1PYjB";
export const iconWhiteArrow = "index-module--icon-white-arrow--1tQsO";
export const hasTextUnderline = "index-module--has-text-underline--30hYL";
export const buttonWidthLr = "index-module--button-width-lr--2UWmq";
export const nextBtn = "index-module--next-btn--WLcKP";
export const pop = "index-module--pop--2sOg9";
export const formContainer = "index-module--form-container--DODZ6";
export const orangeLine = "index-module--orange-line--18La5";
export const isOrange = "index-module--is-orange--2-m29";
export const speech = "index-module--speech--1SRqd";
export const priceBox = "index-module--priceBox--2Dn9X";
export const fullWidth = "index-module--fullWidth--kp-SA";
export const priceDetail = "index-module--priceDetail---dpO4";
export const benefit = "index-module--benefit--3uWkt";
export const boxInBorder = "index-module--boxInBorder--_o-3M";
export const yen = "index-module--yen--3D4oq";
export const quickThumbnail = "index-module--quick-thumbnail--29Iy9";